<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">CAMBIAR ESTATUS</p>
        <span class="material-icons close_modal_icon" @click="$emit('close-modal')">close</span>
      </header>
      <section class="modal-card-body p-6">
        <div class="columns is-multiline">
          <div class="column is-12">
            <p class="paragraph">
              Selecciona un estatus:
            </p>
          </div>
          <div class="column is-5-desktop is-6-tablet">
            <div class="select slct is-fullwidth">
              <select v-model="localStatusId">
                <option selected disabled :value="null">Estatus</option>
                <option
                  v-for="item in statusQuotationList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button button_micro_dark_outlined"
          :disabled="salesLoaderSpinner"
          @click="$emit('close-modal')"
        >
          CANCELAR
        </button>
        <button
          class="button button_micro_dark"
          :class="{ 'is-loading' : salesLoaderSpinner }"
          @click="onUpdateSaleStatus"
        >
          GUARDAR
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalStatusQuotation',
  computed: {
    ...mapGetters(['salesLoaderSpinner'])
  },
  data () {
    return {
      localStatusId: null
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    statusId: {
      type: Number,
      default: null
    },
    statusQuotationList: {
      type: Array,
      default: () => []
    }
  },
  beforeMount () {
    this.localStatusId = this.statusId
  },
  methods: {
    ...mapActions(['updateStatusQuote']),
    async onUpdateSaleStatus () {
      const { success } = await this.updateStatusQuote({
        quoteId: this.$route.params.quotationId,
        statusId: this.localStatusId
      })
      if (success) {
        this.$emit('success-update-status')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    border-radius: 18px;
  }
  .modal-card-head, .modal-card-foot {
    background-color: white;
  }
  .modal-card-foot {
    border-top: none;
    justify-content: center;
  }

  .button {
    font-size: 14px;
    width: 30%;
  }
</style>
